import React from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { Provider } from 'unstated'
import 'lazysizes'
import ReactDOM from "react-dom/client";
// import '~bootstrap/dist/scss/bootstrap.scss'
import './src/styles/global.css'

export const wrapRootElement = ({ element }) => {
  const ConnectedRootElement = (
      <GoogleReCaptchaProvider reCaptchaKey="6Le7RpYcAAAAAKFafKs45D64zVYA03LBxMS31wVx">
        {element}
      </GoogleReCaptchaProvider>
  )

  return ConnectedRootElement
}

export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container)
    root.render(element)
  }
}
