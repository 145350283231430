'use strict'
var DRIFT_CHAT_SELECTOR = '.drift-open-chat'
var _typeof = typeof Symbol === 'function' && typeof Symbol.iterator === 'symbol' ? function (obj) { return typeof obj } : function (obj) { return obj && typeof Symbol === 'function' && obj.constructor === Symbol && obj !== Symbol.prototype ? 'symbol' : typeof obj }
function forEachElement (selector, fn) {
  var elements = document.querySelectorAll(selector)
  for (var i = 0; i < elements.length; i++) { fn(elements[i], i) }
}
function openSidebar (driftApi, event) {
  event.preventDefault()
  driftApi.sidebar.open()
  return false
}
function ready (fn) {
  if (document.readyState !== 'loading') {
    fn()
  } else if (document.addEventListener) {
    document.addEventListener('DOMContentLoaded', fn)
  } else {
    document.attachEvent('onreadystatechange', function () {
      if (document.readyState !== 'loading') { fn() }
    })
  }
}
exports.onInitialClientRender = function () {
  // if (/*process.env.NODE_ENV === 'production' &&*/ (typeof drift === 'undefined' ? 'undefined' : _typeof(drift)) === 'object' && window.driftAppId) {
  // if (window.driftAppId) {
  if (process.env.NODE_ENV === 'production') {
    window.drift.SNIPPET_VERSION = '0.3.1'
    window.drift.load(window.driftAppId)

    window.drift.on('ready', function (api) {
      var handleClick = openSidebar.bind(this, api)
      forEachElement(DRIFT_CHAT_SELECTOR, function (el) {
        el.addEventListener('click', handleClick)
      })
    })
  }
}
